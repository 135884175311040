import React from 'react'
import tw, { styled } from 'twin.macro'
import PropTypes from 'prop-types'

const Flag = styled.div`
  border-left: 4rem solid transparent !important;
  border-top: 4rem solid;
  ${tw`border-green-500`}

  > span {
    ${tw`uppercase text-center w-14 absolute transform rotate-45 text-white font-sans font-bold`}
    top: 10px;
    right: -7px;
    font-size: 10px;
    line-height: 1;
  }
`

function CornerFlag({ children, ...props }) {
  return (
    <div tw="absolute right-1 top-1 z-10 rounded-tr overflow-hidden" {...props}>
      <Flag>
        <span>{children}</span>
      </Flag>
    </div>
  )
}

CornerFlag.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default CornerFlag
