/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { MapPinIcon, DevicePhoneMobileIcon } from '@heroicons/react/24/outline'
import { Power3, gsap } from 'gsap'
import tw, { styled } from 'twin.macro'
import LocationStatus from '../LocationStatus'
import Button from '../Button'
import CardContainer from '../CardContainer'
import ServiceIcon from '../ServiceIcon'
import useOpenStatus from '../../hooks/useOpenStatus'
import CornerFlag from '../CornerFlag/CornerFlag';

// Could move this to an import if we wanted to clean things up
const Distance = styled.div`
  span {
    ${tw`absolute bg-white z-10 text-purple-400 font-bold text-xs pr-2`}
  }

  &:after {
    ${tw`relative w-full h-px bg-purple-200 inline-block mb-3`}
    content: '';
  }
`

const LocationCard = forwardRef(({ location, link, flag, openDate, ...props }, ref) => {
  const { distance, name, phone, ageGroups, hoursOfOperation } = location
  const { street, city, postal } = location.address
  const time = useOpenStatus(hoursOfOperation)
  let fullTime = false

  for (let i = 0; i < location.ageGroups.length; i += 1) {
    if (location.ageGroups[i].name === 'infant' || location.ageGroups[i].name === 'toddler' || location.ageGroups[i].name === 'preschool') {
      fullTime = true
    }
  }


  const getIconCharacter = ageGroup => {
    const icon = ageGroup.slice(0, 1).toUpperCase()
    return icon
  }

  let phoneBlock = useRef(null)
  let locationBlock = useRef(null)
  const pTl = gsap.timeline({ paused: true })
  const lTl = gsap.timeline({ paused: true })

  useEffect(() => {
    const phoneIcon = phoneBlock.firstElementChild
    const locationIcon = locationBlock.firstElementChild

    pTl
      .to(phoneIcon, {
        transformOrigin: 'center',
        scale: 0.9,
        rotation: '+10',
        yoyo: true,
        ease: Power3.Elastic,
        duration: 0.1,
      })
      .to(phoneIcon, { rotation: -10, yoyo: true, scale: 1.2, duration: 0.1, ease: Power3.Elastic })
      .to(phoneIcon, { rotation: 0, scale: 1, yoyo: true, duration: 0.2, ease: Power3.Elastic })
      .to(phoneIcon, { delay: 1.7 })

    lTl
      .to(locationIcon, {
        translateY: -5,
        scale: 1.1,
        yoyo: true,
        ease: Power3.Elastic,
        duration: 0.4,
      })
      .to(locationIcon, { translateY: 0, yoyo: true, scale: 1, duration: 0.1 })
      .to(locationIcon, { delay: 1.7 })
  })

  // useEffect(() => {
  //   checkOpenStatus(hoursOfOperation)
  // }, [])

  function onPhoneHover() {
    pTl.repeat(2).restart()
  }

  function onLocationHover() {
    lTl.repeat(2).restart()
  }

  function onPhoneExit() {
    pTl.repeat(0)
  }
  function onLocationExit() {
    lTl.repeat(0)
  }

  return (
    <div ref={ref} {...props} tw="mx-auto w-full">
      <CardContainer tw="pb-5 flex flex-col justify-between">
        {flag ? <CornerFlag>Open Soon</CornerFlag> : null}
        <div>
          <h3 tw="mb-1 mt-0 text-[24px]">
            <Button variant="text" size="inherit" href={link}>
              {name}
            </Button>
          </h3>

          <Distance>{location?.distance ? <span>{distance.toFixed(1)} km away</span> : null}</Distance>

          <div tw="flex flex-col space-y-3">
            <div tw="w-full flex space-x-1 items-center text-gray-200">
              <span tw="font-sans text-gray-700 text-sm font-bold mr-1 whitespace-nowrap">Age Groups</span>
              {ageGroups.map((ageGroup, index) => (
                <ServiceIcon key={index} name={ageGroup.name}>
                  <span tw="font-proxima font-bold text-center">{getIconCharacter(ageGroup.name)}</span>
                </ServiceIcon>
              ))}
            </div>
            <div tw="flex text-sm items-start">{!flag && <LocationStatus open={time.open} closed={time.closed} isFulltime={fullTime} />}</div>

            {/* <div tw="flex mb-3 font-bold space-x-2  text-sm"> */}
            {/* {!flag && <LocationStatus text={openStatus} />} */}
            <div
              onMouseEnter={onPhoneHover}
              onMouseLeave={onPhoneExit}
              tw="flex text-sm items-start"
              ref={el => (phoneBlock = el)}
            >
              <DevicePhoneMobileIcon tw="text-purple-400 w-5 h-5 inline-block mr-1" />
              <span>
                <a href={`tel:+${phone}`}>{phone}</a>
              </span>
            </div>
            {/* </div> */}

            <div
              onMouseEnter={onLocationHover}
              onMouseLeave={onLocationExit}
              ref={el => (locationBlock = el)}
              tw="flex"
            >
              <MapPinIcon tw="text-purple-400 w-5 h-5 inline-block mr-1" />
              <p tw="text-sm">
                {street},{' '}<span tw="whitespace-nowrap">{city}, ON {postal}</span>
              </p>
            </div>
          </div>
        </div>

        {link ? (
          <div tw="flex justify-end mt-2">
            <Button variant="text" tw="text-[16px]" size="medium" icon="ArrowLongRightIcon" href={link}>
              Learn more
            </Button>
          </div>
        ) : null}
      </CardContainer>
    </div>
  )
})

LocationCard.propTypes = {
  location: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.shape({
      street: PropTypes.string,
      city: PropTypes.string,
      province: PropTypes.string,
      postal: PropTypes.string
    }),
    distance: PropTypes.number,
    phone: PropTypes.string,
    ageGroups: PropTypes.array,
  }),
  link: PropTypes.string,
}

LocationCard.defaultProps = {
  location: {
    name: 'Unspecified',
    address: 'Unspecified',
    distance: null,
    phone: '(---) --------',
  },
  link: null,
}

LocationCard.displayName = 'LocationCard'

export default LocationCard
