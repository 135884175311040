import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css' // optional
import 'tippy.js/animations/shift-away.css'
import tw, { styled } from 'twin.macro'

function ServiceIcon({ children, name, ...props }) {
  let nameFormatted
  if (name !== 'schoolAge') {
    nameFormatted = `${name.charAt(0).toUpperCase() + name.slice(1)}`
  } else {
    nameFormatted = `${name.charAt(0).toUpperCase() + name.slice(1, 6)} ${name.slice(6)}`
  }

  const infantStyle = tw`text-purple-500 border-purple-500`
  const toddlerStyle = tw`text-blue border-blue`
  const preschoolStyle = tw`text-green-400 border-green-400`
  const kinderStyle = tw`text-yellow-400 border-yellow-400`
  const schoolStyle = tw`text-red-500 border-red-500`

  // TODO: Figure out how to style this dang arrow!
  // const StyledTippy = styled(Tippy)`
  //   .tippy-tooltip[data-placement^='top'] > .tippy-arrow {
  //     border-top-color: red;
  //   }
  // `

  return (
    <Tippy content={nameFormatted} placement="top" animation="shift-away" tw="pb-0.5 text-xs font-sans" theme="bootstrap">
      <div
        css={[
          name === 'infant' && infantStyle,
          name === 'toddler' && toddlerStyle,
          name === 'preschool' && preschoolStyle,
          name === 'kindergarten' && kinderStyle,
          name === 'schoolAge' && schoolStyle,
        ]}
        tw="flex border-2 rounded-full h-5 w-5 justify-center items-center text-xs"
      >
        {children}
      </div>
    </Tippy>
  )
}

ServiceIcon.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  name: PropTypes.string,
}

export default ServiceIcon
