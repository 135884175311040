import React, { useState, useEffect, useRef } from 'react'
import { isBefore } from 'date-fns'
import PropTypes from 'prop-types'
import LocationCard from '../LocationCard'
import Button from '../Button'
import { Grid } from './LocationGrid.styles'

const INITIAL_LOCATION_COUNT = 100

/**
 * LocationsGrid Component - Handles the display of the location cards
 *
 * @param   {arr}  locations  locations from the graphQL query
 *
 * @return  {jsx}
 *
 * TODO - Animations, nicer UI
 */
function LocationsGrid({ locations, coords, ...props }) {
  const [count, setCount] = useState(INITIAL_LOCATION_COUNT)
  const [showAllLocations, setShowAllLocations] = useState(true)
  // in, animatedIn, out, animatedOut
  let openDate = null

  const cards = useRef([])

  const checkOpenDate = location => {
    let openYear
    let openDay
    let openMonth
    let flag
    const isOpen = []
    const currentDate = new Date()

    location.ageGroups.map(ageGroup => {
      ageGroup.programs.map(data => {
        openYear = data.open.slice(0, 4)
        openDay = data.open.slice(5, 7)
        openMonth = data.open.slice(8, data.open.length)
        const checkingDate = new Date(openYear, openMonth - 1, openDay) // date-fns starts months at 00, - 1 for isBefore function

        isOpen.push(isBefore(currentDate, checkingDate))

        // Set first date checked for comparison
        if (openDate === null) {
          openDate = checkingDate
        }

        // If the date we're checking is before the first open date we set, set first open to date we're checking
        // Open date needs to be the date of whichever program is open first
        if (isBefore(checkingDate, openDate)) {
          openDate = checkingDate
        }
        return openDate
      })
      return openDate
    })

    // If any of the programs are open (includes true), return false - no flag
    if (isOpen.includes(false)) {
      flag = false
      openDate = null
    } else {
      flag = true
    }

    return flag
  }

  const addToRefs = el => {
    if (el && !cards.current.includes(el)) {
      cards.current.push(el)
    }
  }

  return (
    <Grid>
      {locations.map((location, idx) => (
        <LocationCard
          ref={addToRefs}
          key={`${location.id}_${idx}`}
          flag={checkOpenDate(location)}
          openDate={openDate}
          location={{
            address: {
              street: location.address.street,
              city: location.address.city,
              province: location.address.province,
              postal: location.address.postal,
            },
            name: location.name,
            phone: location.phone,
            distance: location.address?.distance,
            ageGroups: location.ageGroups,
            hoursOfOperation: location.hoursOfOperation,
          }}
          link={location?.page?.uri}
        />
      ))}
    </Grid>
  )
}

LocationsGrid.propTypes = {
  //
}

export default LocationsGrid
