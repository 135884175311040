import React, { useState } from 'react'
import PropTypes from 'prop-types'
import AnimatedClock from './AnimatedClock'
import Clock from './Clock'

function LocationStatus({ open, closed, isFulltime }) {
  const [isHover, setIsHover] = useState(false)

  return (
    <div
      tw="flex flex items-start mr-5"
      style={{ marginLeft: 3 }}
      onMouseEnter={() => {
        setIsHover(true)
      }}
      onMouseLeave={() => {
        setIsHover(false)
      }}
    >
      <div tw="text-purple-400 w-5 h-5 !min-w-[17px] flex justify-start items-center">{isHover ? <AnimatedClock /> : <Clock />}</div>
      <span tw="inline-block space-x-2 text-sm" style={{ marginLeft: '5.8px' }}>
        {!isFulltime
          ? `Weekdays - ${open} to morning school bell and afternoon school bell to ${closed}`
          : `Weekdays from ${open} to ${closed}`}
      </span>
    </div>
  )
}

LocationStatus.propTypes = {
  open: PropTypes.string,
  closed: PropTypes.string,
  isFulltime: PropTypes.bool,
}

export default LocationStatus
