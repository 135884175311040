import React from 'react'
import tw, { css, styled } from 'twin.macro'

const ageGroups = [
  {
    age: 'Infant',
    color: '#81318F',
  },
  {
    age: 'Toddler',
    color: '#3B82F6',
  },
  {
    age: 'Preschool',
    color: '#27AB5E',
  },
  {
    age: 'Kindergarten',
    color: '#F7CE00',
  },
  {
    age: 'School Age',
    color: '#f43f5e',
  },
]

const Legend = () => {
  const AgeIcon = styled.div`
    ${tw`px-2 flex border-2 rounded-full h-5 w-5 justify-center text-xs items-center font-bold font-proxima`}
    color: ${props => props?.color || 'purple'};
    border-color: ${props => props?.color || 'purple'};
  `
  return (
    <div tw="flex flex-wrap space-x-2 md:space-x-6 text-center justify-center mx-auto pb-4">
      {ageGroups.map((a, key) => (
        <div key={key} tw="flex justify-start space-x-1">
          <AgeIcon color={a.color}>
            <span>{a.age.slice(0, 1).toUpperCase()}</span>
          </AgeIcon>
          <span tw="text-sm font-proxima">{a.age}</span>
        </div>
      ))}
    </div>
  )
}

export default Legend
